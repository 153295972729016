<template>
  <div>

    <div v-if="showSearchIconAppTemplate">
      <div style="margin-bottom: -20px; position: relative; z-index: 1">
        <el-select
            style="width: 200px"
            class="button-left-class_search"
            filterable
            v-model="getAllTemplateAppRequest.idSchool"
            placeholder="Chọn trường"
            @change="searchHeaderMethodTemplate()"
        >
          <el-option
              v-for="item in schoolList"
              :key="item.id"
              :value="item.id"
              :label="item.schoolName"
          ></el-option>
        </el-select>
        <el-select
            class="button-left-class_search"
            filterable
            placeholder="Chọn loại App"
            v-model="getAllTemplateAppRequest.appType"
            @change="searchHeaderMethodTemplate()"
        >
          <el-option
              v-for="item in appTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>
        <el-input
            v-model="getAllTemplateAppRequest.nameTemplate"
            style="width: 300px"
            placeholder="Nhập tên gói"
            @clear="searchHeaderMethodTemplate()"
            clearable
            @keyup.enter.native="searchHeaderMethodTemplate()"
        >
          <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchHeaderMethodTemplate()"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div>
      <el-tabs type="card" @tab-click="handleClickTab" v-model="activeTabName">
        <!-- sinh nhật -->
        <el-tab-pane label="Sinh nhật" name="birthdayTab">
          <el-table
              ref="multipleTable"
              :empty-text="textTableBirthDay"
              v-loading="loadingData"
              :element-loading-text="$tableLoading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255,255,255, 0)"
              highlight-current-row
              :data="birthdaySampleList"
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeMedia"
              :max-height="$tableMaxHeight"
              border
          >
            <el-table-column
                type="index"
                label="STT"
                width="50"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="birthdayType"
                label="Đối tượng"
                width="130"
                align="center"
            ></el-table-column>
            <el-table-column prop="content" label="Nội dung"></el-table-column>
            <el-table-column label="Kích hoạt" width="90" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    @change="activeMutipleRow(scope.row, 'activeColumn')"
                    v-model="scope.row.active"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Gửi qua App" width="100" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    @change="activeMutipleRow(scope.row, 'activeAppColumn')"
                    v-model="scope.row.appSend"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Gửi qua SMS" width="100" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    @change="activeMutipleRow(scope.row, 'activeSMSColumn')"
                    v-model="scope.row.smsSend"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Ảnh" width="150" align="center">
              <template slot-scope="scope">
                <el-image
                    style="width: 120px; height: 120px"
                    :src="scope.row.urlPicture"
                    fit="fill"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="Tác vụ" width="140" align="center">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="success"
                    @click="updateBirthdaySample(scope.row)"
                >Sửa
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <!-- ngày lễ -->
        <el-tab-pane label="Ngày lễ" name="celebrateTab">
          <el-table
              ref="multipleTable"
              :empty-text="textTableCelebrate"
              v-loading="loadingData"
              :element-loading-text="$tableLoading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255,255,255, 0)"
              highlight-current-row
              :data="celebrateSampleList"
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeMedia"
              :max-height="$tableMaxHeight"
              border
          >
            <el-table-column
                fixed
                type="index"
                label="STT"
                width="50"
                align="center"
            ></el-table-column>
            <el-table-column fixed label="Ngày lễ" width="80" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.date }}/{{ scope.row.month }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="title"
                label="Tiêu đề"
                min-width="300"
            ></el-table-column>
            <el-table-column
                prop="content"
                label="Nội dung"
                min-width="400"
            ></el-table-column>
            <el-table-column
                prop="gender"
                label="Giới tính"
                width="80"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="type"
                label="Đối tượng"
                width="100"
                align="center"
            ></el-table-column>
            <el-table-column label="Kích hoạt" width="90" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    @change="activeMutipleCelebrateRow(scope.row, 'activeColumn')"
                    v-model="scope.row.active"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Gửi qua App" width="100" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    @change="
                    activeMutipleCelebrateRow(scope.row, 'activeAppColumn')
                  "
                    v-model="scope.row.appSend"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Gửi qua SMS" width="100" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    @change="
                    activeMutipleCelebrateRow(scope.row, 'activeSMSColumn')
                  "
                    v-model="scope.row.smsSend"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="Tác vụ"
                width="140"
                align="center"
            >
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="success"
                    @click="updateCelebrateSampleMethod(scope.row)"
                >Sửa
                </el-button
                >
                <el-button
                    size="mini"
                    type="danger"
                    @click="deleteCelebrateSampleMethod(scope.row)"
                >Xóa
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="float: right; margin: 15px 0">
            <el-button
                class="button-bottom"
                type="success"
                @click="createWishesSampleMethod()"
            >Thêm ngày lễ
            </el-button
            >
          </div>
        </el-tab-pane>

        <!-- DVR link -->
        <el-tab-pane label="DVR Link" name="mediaConfig">
          <el-table
              ref="multipleTable"
              highlight-current-row
              :data="dvrDataList"
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeMedia"
              :max-height="$tableMaxHeight"
              border
          >
            <el-table-column
                type="index"
                label="STT"
                width="50"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="name"
                label="Hãng camera"
                width="150"
                align="center"
            ></el-table-column>
            <el-table-column prop="link" label="Link"></el-table-column>
            <el-table-column
                prop="note"
                label="Ghi chú"
                width="350"
            ></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <BirthdaySampleUpdate
        :dialogVisible="showUpdateDialog"
        @dialog-close="dialogCloseUpdateMethod()"
        ref="BirthdaySampleUpdate"
    />
    <CelebrateSampleCreate
        :dialogVisible="showCelebrateCreateDialog"
        @dialog-close="dialogCloseCelebrateCreateMethod()"
    />
    <CelebrateSampleUpdate
        :dialogVisible="showCelebrateUpdateDialog"
        @dialog-close="dialogCloseCelebrateUpdateMethod()"
        ref="CelebrateSampleUpdate"
    />
  </div>
</template>


<script>
import SystemConfigService from "@/services/AdminService/SystemConfigService";
import BirthdaySampleUpdate from "./BirthdaySampleUpdate.vue";
import CelebrateSampleCreate from "./CelebrateSampleCreate.vue";
import CelebrateSampleUpdate from "./CelebrateSampleUpdate.vue";
import {mapActions, mapState} from "vuex";


export default {
  components: {
    BirthdaySampleUpdate,
    CelebrateSampleCreate,
    CelebrateSampleUpdate,
  },
  created() {
    this.$store.dispatch('schoolStore/fetchDataAgentList');
  },
  computed: {
    ...mapState('schoolStore', ['agentList']),
    ...mapState('schoolStore', ['schoolList'])
  },
  watch: {
    agentList: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.getIdAgent();
        }
      },
    },
  },
  data() {
    return {
      sizeLimit: 10,
      totalPages:0,
      currentPagePagination: 1,
      activeTabName: "birthdayTab",
      loadingData: true,
      textTableBirthDay: "",
      textTableCelebrate: "",
      birthdaySampleList: [],
      celebrateSampleList: [],
      multipleSelectionMedia: [],
      albumRadio: false,
      loaddingButton: false,
      showUpdateDialog: false,
      showCelebrateUpdateDialog: false,
      showDetailTemplateAppDialog: false,
      showUpdateTemplateAppDialog: false,
      showAddTemplateAppDialog: false,
      showCelebrateCreateDialog: false,
      loaddingButtonClass: false,
      dvrDataList: [
        {
          name: "Dahua",
          link: "rtsp://Username:Password@domain:Port/cam/realmonitor?channel=A&subtype=B",
          note: "A là kênh, B=0 luồng chính, B=1 luồng phụ.",
        },
        {
          name: "Hik",
          link: "rtsp://Username:Password@domain:Port/Streaming/channels/A0B",
          note: "A là kênh, B=1 luồng chính, B=2 luồng phụ.",
        },
        {
          name: "Xmeye",
          link: "rtsp://domain:Port/user=Username&password=Password&channel=A&stream=B.sdp?",
          note: "A là kênh, B=0 luồng chính, B=1 luồng phụ.",
        },
      ],


      idAgent: "",

      layout: "sizes, prev, pager, next",
      getAllTemplateAppRequest: {
        idSchool: 1,
        appType: "plus",
        nameTemplate: "",
        pageNumber: 1,
        pageTotal: this.$pageTotalDefault,
        maxPageItem: 10,
      },
      textTableTemplateAppTab: "",
      templateAppList: [],
      showSearchIconAppTemplate: false,
      appTypeList: [
        {key: "plus", value: "App Plus"},
        {key: "teacher", value: "App Teacher"},
        {key: "parent", value: "App Onekids"},
      ],
      multipleSelectionTemplate: [],
      loadingDataTemplate: true,
    };
  },
  methods: {
    ...mapActions('schoolStore', ["fetchDataSchoolList"]),
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyleDetail({row}) {
      if (
          row.absentStatus == "Nghỉ có phép" ||
          row.absentStatus == "Nghỉ không phép"
      ) {
        return "color: red";
      } else if (row.absentStatus == "Chưa điểm danh") {
        return "color: #409EFF";
      }
    },
    handleSelectionChangeMedia(val) {
      this.multipleSelectionMedia = val;
    },
    handleCommand(command) {
      if (command == "exportDate") {
        this.exportExcelAttendanceDate();
      } else if (command == "exportMonth") {
        this.exportExcelAttendanceMonth();
      }
    },

    handleClickTab(tab) {
      let tabNameClick = tab.name;
      if (tabNameClick == "birthdayTab") {
        this.showSearchIconAppTemplate = false;
        this.findAllBirthdaySampleMethod();
      } else if (tabNameClick == "celebrateTab") {
        this.showSearchIconAppTemplate = false;
        this.findAllCelebrateSampleMethod();
      }

    },
    dialogCloseUpdateMethod() {
      this.showUpdateDialog = false;
      this.findAllBirthdaySampleMethod();
    },
    dialogCloseCelebrateUpdateMethod() {
      this.showCelebrateUpdateDialog = false;
      this.findAllCelebrateSampleMethod();
    },
    updateBirthdaySample(row) {
      this.showUpdateDialog = true;
      this.$refs.BirthdaySampleUpdate.getDataInitial(row);
    },
    updateCelebrateSampleMethod(row) {
      this.showCelebrateUpdateDialog = true;
      this.$refs.CelebrateSampleUpdate.getDataInitial(row);
    },
    dialogCloseCelebrateCreateMethod() {
      this.showCelebrateCreateDialog = false;
      this.findAllCelebrateSampleMethod();
    },
    createWishesSampleMethod() {
      this.showCelebrateCreateDialog = true;
    },
    /**
     * delete celebrate
     */
    deleteCelebrateSampleMethod(row) {
      this.$confirm(
          "Bạn có chắc chắn muốn xóa ngày lễ đã chọn?",
          "Thông báo!",
          {
            distinguishCancelAndClose: true,
            closeOnClickModal: false,
            confirmButtonText: "Có",
            cancelButtonText: "Không",
          }
      ).then(() => {
        SystemConfigService.deleteCelebrateSample(row.id)
            .then((resp) => {
              let data = resp.data.data;
              console.log(data);
              this.$message({
                message: "Xóa thành công",
                type: "success",
              });
              this.findAllCelebrateSampleMethod();
            })
            .catch((err) => {
              console.log(err);
              this.$message({
                message: "Xóa thất bại",
                type: "error",
              });
            });
      });
    },
    /**
     * cập nhật kích hoạt sinh nhật
     */
    activeMutipleRow(row, type) {
      let textAlert = "";
      if (type == "activeColumn") {
        if (row.active) {
          textAlert = "Kích hoạt";
        } else {
          textAlert = "Hủy kích hoạt";
        }
      } else if (type == "activeAppColumn") {
        if (row.appSend) {
          textAlert = "Kích hoạt gửi qua App";
        } else {
          textAlert = "Hủy kích hoạt gửi qua App";
        }
      } else if (type == "activeSMSColumn") {
        if (row.smsSend) {
          textAlert = "Kích hoạt gửi qua SMS";
        } else {
          textAlert = "Hủy kích hoạt gửi qua SMS";
        }
      }
      SystemConfigService.updateActiveBirthdaySample(row)
          .then((resp) => {
            let checkActive = resp.data.data;
            console.log(checkActive);
            this.$message({
              message: textAlert + " thành công",
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              message: textAlert + " thất bại",
              type: "error",
            });
          });
    },
    /**
     * cập nhật kích hoạt celebrate
     */
    activeMutipleCelebrateRow(row, type) {
      let textAlert = "";
      if (type == "activeColumn") {
        if (row.active) {
          textAlert = "Kích hoạt";
        } else {
          textAlert = "Hủy kích hoạt";
        }
      } else if (type == "activeAppColumn") {
        if (row.appSend) {
          textAlert = "Kích hoạt gửi qua App";
        } else {
          textAlert = "Hủy kích hoạt gửi qua App";
        }
      } else if (type == "activeSMSColumn") {
        if (row.smsSend) {
          textAlert = "Kích hoạt gửi qua SMS";
        } else {
          textAlert = "Hủy kích hoạt gửi qua SMS";
        }
      }
      SystemConfigService.updateActiveCelebrateSample(row)
          .then((resp) => {
            let checkActive = resp.data.data;
            console.log(checkActive);
            this.$message({
              message: textAlert + " thành công",
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              message: textAlert + " thất bại",
              type: "error",
            });
          });
    },

    /**
     * tìm kiếm tất cả mẫu sinh nhật
     */
    findAllBirthdaySampleMethod() {
      this.loadingData = true;
      SystemConfigService.findAllBirthdaySample()
          .then((resp) => {
            this.birthdaySampleList = resp.data.data;
          })
          .catch((err) => {
            console.log(err);
            this.birthdaySampleList = null;
          })
          .finally(() => {
            if (this.birthdaySampleList.length == 0) {
              this.textTableBirthDay = this.$tableEmpty;
            }
            this.loadingData = false;
          });
    },
    findAllCelebrateSampleMethod() {
      this.loadingData = true;
      SystemConfigService.findAllCelebrateSample()
          .then((resp) => {
            this.celebrateSampleList = resp.data.data;
          })
          .catch((err) => {
            console.log(err);
            this.celebrateSampleList = null;
          })
          .finally(() => {
            if (this.celebrateSampleList.length == 0) {
              this.textTableCelebrate = this.$tableEmpty;
            }
            this.loadingData = false;
          });
    },
  },
  beforeMount() {
    this.findAllBirthdaySampleMethod();
  },


};
</script>


<style lang="scss" scoped>
/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #78a5e7;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-tabs__nav-scroll {
  float: right;
}

/deep/ .el-tabs__header {
  margin-bottom: 0;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #78a5e7;
}

/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}

/deep/ .el-table td,
/deep/ .el-table th {
  padding: 10px 0;
}

/deep/ .el-table .cell {
  line-height: 15px;
}

/deep/ .el-table th > .cell {
  font-size: 13px;
}

/deep/ .el-checkbox__inner {
  border: 1px solid gray;
}

/deep/ .el-table .cell {
  white-space: pre-line;
  line-height: 20px;
  word-break: break-word;
}

.input-common {
  margin-right: 5px;
}

.button-bottom {
  border-radius: 0;
  margin-left: 3px;
}

.button-click {
  float: right;
  margin: 20px 0 40px 0;
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0;
  margin: 3px 0;
  background-color: #5fb44a;
  border: 1px solid #ebeef5;
  border-radius: 0;
  box-shadow: 0 2px 12px 0 #0000001a;
}

.el-dropdown-menu li {
  color: white;
  border-top: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
}

.over-table {
  width: 97%;
  margin: 0 auto;
}

.table-data {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ebeef5;

  // display: inline-block;
}

.wrapper-table {
  height: 600px;
  overflow-y: scroll;
  border-bottom: 1px solid #ebeef5;
}

// .table-header {
//   position: sticky;
// }
// table{

// }
thead {
  background: #78a5e7;
  color: white;
  font-weight: bold;
  // display: block;
  // width: 90%;
}

tbody {
  color: #606266;
  font-size: 14px;
  overflow: auto;
  // height: 600px;
  font-family: Arial, Helvetica, sans-serif;
  // display: block;
  // width: 90%;
}

td {
  padding: 12px;
}

.table-index {
  width: 10px;
  text-align: center;
}

.table-item-name {
  width: 200px;
  text-align: center;
}

.table-action {
  width: 200px;
  text-align: center;
}

.color-text-reply {
  color: #4177e2;
}

.input-data {
  width: 60%;
}

.disable-row {
  background: #e4e7ed;
}

.button-left-class_search {
  margin-right: 5px;
  width: 150px;
}
</style>