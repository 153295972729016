import { render, staticRenderFns } from "./CelebrateSampleCreate.vue?vue&type=template&id=54a7bf52&scoped=true"
import script from "./CelebrateSampleCreate.vue?vue&type=script&lang=js"
export * from "./CelebrateSampleCreate.vue?vue&type=script&lang=js"
import style0 from "./CelebrateSampleCreate.vue?vue&type=style&index=0&id=54a7bf52&lang=css"
import style1 from "./CelebrateSampleCreate.vue?vue&type=style&index=1&id=54a7bf52&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a7bf52",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54a7bf52')) {
      api.createRecord('54a7bf52', component.options)
    } else {
      api.reload('54a7bf52', component.options)
    }
    module.hot.accept("./CelebrateSampleCreate.vue?vue&type=template&id=54a7bf52&scoped=true", function () {
      api.rerender('54a7bf52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/master/systemconfig/CelebrateSampleCreate.vue"
export default component.exports